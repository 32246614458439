import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: AVTR1,
    dname: `name one`,
    review: `Lorem ipsum dolor sit amet consectetur adipisicing elit
     Qui a eligendi perferendis porro natus beatae temporibus possimus.
      Possimus ad facere veniam, velit, ullam
       similique, labore sequi illum vero maiores excepturi?`
  },
  {
    avatar: AVTR2,
    dname: `name two`,
    review: `Lorem ipsum dolor sit amet consectetur adipisicing elit
     Qui a eligendi perferendis porro natus beatae temporibus possimus.
      Possimus ad facere veniam, velit, ullam
       similique, labore sequi illum vero maiores excepturi?`
  },
  {
    avatar: AVTR3,
    dname: `name three`,
    review: `Lorem ipsum dolor sit amet consectetur adipisicing elit
     Qui a eligendi perferendis porro natus beatae temporibus possimus.
      Possimus ad facere veniam, velit, ullam
       similique, labore sequi illum vero maiores excepturi?`
  },
  {
    avatar: AVTR4,
    dname: `name four`,
    review: `Lorem ipsum dolor sit amet consectetur adipisicing elit
     Qui a eligendi perferendis porro natus beatae temporibus possimus.
      Possimus ad facere veniam, velit, ullam
       similique, labore sequi illum vero maiores excepturi?`
  },

]



const Testimonials = () => {
  return (
    <section id='testimonials'

    >
      <h5> Review From Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonial__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')}
      >

        {
          data.map(({ avatar, dname, review }, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} alt={dname} />
                </div>
                <h5 className="client__name">{dname}</h5>
                <small className="client__review">
                  {review}
                </small>
              </SwiperSlide>
            )
          })
        }

      </Swiper>
    </section>
  )
}

export default Testimonials